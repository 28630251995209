// Variables
$dark-blue: #0568f0;
$light-blue: #2d84fb;
$dark-gray: #3c4858;

.timeline-item {
  position: relative;
  max-height: 100%;
  transition: all 0.3s ease-in-out !important;

  &.overlay-timeline-item {
    max-height: 222px;
    overflow: hidden;
  }
  .btnExpandContainer {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -24px;
    padding: 10px 0;
    border-radius: 0 0 6px 6px;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(198, 198, 198, 0.93)
    );

    button {
      background-color: $light-blue;
      border: none;
      border-radius: 50%;
      transform: rotate(180deg);
      padding: 6px 10px;
      color: white;
      box-shadow: #00000054 0px 0px 5px 1px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $dark-blue;
      }
    }
  }

  &.no-overlay {
    .btnExpandContainer {
      padding: 0px 0 5px 0;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(198, 198, 198, 0.25)
      );
      button {
        transform: rotate(0deg);
        font-size: 10px;
        padding: 2px 5px;
      }
    }
  }
}

.dark-theme {
  .timeline-item {
    .btnExpandContainer {
      background: linear-gradient(rgba(255, 255, 255, 0), rgb(3, 28, 55));
    }
    &.no-overlay {
      .btnExpandContainer {
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(3, 28, 55, 0.5)
        );
      }
    }
  }
}

// Custom css
nav {
  #nav-bar-style {
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    line-height: 1.5;
    color: $dark-blue !important;
  }
}
.footer-brand {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  line-height: 1.5;
  color: white;
  &:hover {
    color: $dark-blue;
  }
}
.header-icons {
  li {
    > span {
      color: $dark-gray;
      border: 2px solid $dark-gray;
      display: inline-block;
      height: 32px;
      text-align: center;
      font-size: 15px;
      width: 32px;
      line-height: 30px;
      transition: all 0.4s ease;
      border-radius: 7px;

      &.language-switcher {
        width: auto;
        padding: 0 8px;
        svg {
          margin-right: 8px;
        }
        span {
          cursor: pointer;
          font-weight: 600;
          font-family: Montserrat, sans-serif;
          &.english-lang {
            margin-right: 8px;
            border-right: 1px solid #adb5bd;
            padding-right: 8px;
          }
          &.active {
            color: $dark-blue;
          }
          &:hover {
            color: $dark-gray !important;
          }
        }
      }
      &:hover {
        border-color: $dark-blue;
        background-color: $dark-blue;
        cursor: pointer;
        color: white !important;
        span {
          color: white !important;
        }
      }
    }
  }
}
.service-wrapper {
  .text-muted {
    b {
      color: $dark-blue;
    }
  }
}
.no-stroke {
  stroke-width: 0;
}
.with-stroke {
  stroke-width: 1px;
}
.mb2 {
  margin-bottom: 2px;
}

.page-next-level .page-next.custom {
  li {
    margin: 0;
    svg {
      width: 12px;
      stroke-width: 50;
      height: 12px;
      margin: 0 5px 3px;
    }
    &::after {
      display: none;
    }
    a {
      padding-right: 0;
    }
  }
}
.bg-overlay-gray {
  background-color: #a1a8bb8f !important;
}

.disabled-link {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
.categoryfilt {
  cursor: pointer;
  &:hover {
    color: $dark-blue;
  }
}
.searchform {
  .inpt-search {
    position: relative;
    .search-icon-btn {
      position: absolute;
      top: 13px;
      right: 20px;
      color: black;
      width: 20px;
      height: 20px;
    }
    #searchsubmit {
      top: 3px;
    }
  }
  &::after {
    display: none;
  }
}

.title-heading {
  .mainsub {
    font-size: 16px !important;
  }
}

.timeline-item {
  .event {
    b,
    p {
      font-family: Montserrat, sans-serif;
    }
    p {
      em {
        color: #4f4f4f;
      }
    }
  }
}

.navbar-toggler {
  &.active {
    svg {
      transform: rotate(90deg);
      path {
        color: $dark-blue;
      }
    }
  }
  svg {
    transition: all 0.4s ease;
  }
}

.skillsection {
  .container {
    .section-title {
      h3 {
        font-size: 24px !important;
      }
    }
    .hobbies {
      h4 {
        font-size: 16px !important;
      }
    }
  }
}

section {
  .text-muted {
    color: #6c757d !important;
  }
}

.footer {
  color: #e7e7e7 !important;
}

.navbar-nav {
  .nav-item {
    .nav-link {
      font-weight: 900 !important;
      &:hover {
        color: $light-blue !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .herosection {
    .container {
      h2,
      p {
        span {
          background-color: #ffffffdb;
          padding: 2px 0;
        }
      }
    }
  }
}
